<template>
    <div>
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop" @show="filterOpen">
                <template #button-content>
                    <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                    class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>Параметры фильтра</span>
                        <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                    </div>
                    <!--------------- Плановый период ------------------->
                    <div class="filter-block">
                        <b-form-group label="Плановый период">
                            <multiselect
                                ref="bcPlanYear"
                                v-model="planPeriod"
                                track-by="name"
                                label="name"
                                placeholder="Выбрать год"
                                :options="years"
                                :searchable="false"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="chgRegionOrYear"
                            >
                            </multiselect>
                        </b-form-group>
                    </div>
                    <div class="filter-block" v-if="listReg !== null">
                        <b-form-group label="Область/район">
                            <multiselect
                                v-model="curReg"
                                track-by="name_ru"
                                label="name_ru"
                                placeholder="Выбрать регион"
                                :options="listReg"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="chgRegionOrYear"
                                ref="bcRegion"
                            >
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!------------------Варианты----------------->
                    <div class="filter-block">
                        <b-form-group label="Версия бюджета">
                            <multiselect
                                v-model="curVariant"
                                label="name"
                                placeholder="Выбрать вверсию бюджета"
                                :options="variantLst"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="chgData"
                                ref="bcVariant"
                            >
                            </multiselect>
                        </b-form-group>
                    </div>
                    <!--------------- Версия ------------------->
                    <div>
                        <br>
                    </div>
                    <div class="filter-block">
                        <b-form-group>
                            <b-form-radio-group
                                ref="bcVersion"
                                id="radio-group-6"
                                v-model="version"
                                :options="versions"
                                @change="chgData"
                                name="radio-options_6"
                            >
                            </b-form-radio-group>
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-radio-group
                            id="radio-group-5"
                            v-model="selecteds_"
                            :options="option_"
                            name="radio-options_5"
                            @input="$emit('selectedsChanged', selecteds_)"
                        >
                        </b-form-radio-group>
                    </div>
                </div>
            </b-dropdown>
        </div>
        <!--       хлебные крошки-->
        <div class="filter-breadcrumb">
        <span class="item-breadcrumb" v-if="!openFilterWindow && planPeriod" @click="openFilterByRef('bcPlanYear')">
            {{ planPeriod.name }}
        </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && curReg" @click="openFilterByRef('bcRegion')">
            {{ curReg.code }} - {{ curReg.name_ru }}
        </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && curVariant" @click="openFilterByRef('bcVariant')">
            {{ curVariant.name }} - версия
        </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow" @click="openFilterByRef('bcVersion')">
            {{ versions[version].text }}
        </span>
        </div>
        <template>
            <balance-structure-with v-show="selecteds_ === 1" v-if="(local_reg !== null && curVariant !== null)"
                                    ref="balanceformw"
                                    :year="cYear.toString()"
                                    :obl="obl"
                                    :reg="local_reg"
                                    :variant="curVariant.variant_uuid"
                                    :list-reg="listReg"
                                    :version="version"
                                    :data-type="dataType">
            </balance-structure-with>
        </template>
        <template>
            <balance-structure-free v-show="selecteds_ === 2" v-if="(local_reg !== null && curVariant !== null)"
                                    ref="balanceformf"
                                    :year="cYear.toString()"
                                    :obl="obl"
                                    :reg="local_reg"
                                    :variant="curVariant.variant_uuid"
                                    :list-reg="listReg"
                                    :version="version"
                                    :data-type="dataType">
            </balance-structure-free>
        </template>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BalanceStructureFree from '@/modules/budget/BalanceStructureFree';
import BalanceStructureWith from '@/modules/budget/BalanceStructureWith';
import store from '@/services/store';
import {CUR_YEAR} from '../budget-request/components/js/budgetCurYear';
import {variantName} from '@/modules/budget/budgetCorrectHelper';

export default {
    name: 'BalanceForm',
    components: {BalanceStructureWith, BalanceStructureFree},
    props: {
        year: String,
        reg: String,
        dataType: Number
    },
    // watch: {
    //     reg(newVal) {
    //         this.local_reg = newVal;
    //         this.loadVariants();
    //     }
    // },
    data() {
        return {
            local_reg: '',
            day: 0,
            month: 0,
            curDate: 0,
            cYear: '',
            curYear: CUR_YEAR,
            variantBase: [],
            curVariant: null,
            variantLst: [],
            variant: '',
            curReg: null,
            selecteds_: 1,
            obl: null,
            listReg: null,
            option_: [
                {text: 'по структуре', value: 1},
                {text: 'без структуры', value: 2}
            ],
            versions: [
                {value: 0, text: 'АБП'},
                {value: 1, text: 'УЭБП'}
            ],
            years: [],
            planPeriod: null,
            version: 0,
            view: 0,
            develop: 0,
            openFilterWindow: false
        };
    },
    created() {
        const startDate = CUR_YEAR - 1;
        const endDate = CUR_YEAR + 1;
        for (let i = endDate; i >= startDate; i--) {
            this.years.push({name: `${i} - ${i + 2}`, year: i})
        }
        if ([1, 4].includes(this.dataType)) {
            this.curYear = new Date().getFullYear() + 1;
        } else {
            this.curYear = new Date().getFullYear();
        }
        for (const y of this.years) {
            if (y.year === this.curYear) {
                this.planPeriod = y;
                this.cYear = y.year.toString();
            }
        }
        this.selecteds_ = 1;
        // this.$watch('cYear', async function () {
        //     await this.loadVariants;
        // });
        // this.$watch('curReg', async function () {
        //     this.loadVariants;
        // // });
        this.$watch('curVariant', this.chgData);
    },

    async mounted() {
        await this.getObl();
        await this.loadVariants();
        setTimeout(() => {
            if (this.curVariant !== null) {
                this.chgData();
            }
        }, 3000);
    },
    methods: {
        async filterOpen() {
            if (!Array.isArray(this.listReg)) {
                await this.loadRegions();
            }
        },
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl.toString();
                    });
                await this.loadRegions();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },
        async loadRegions() {
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + store.state.user.sub);
                if (response.status === 200) {
                    this.listReg = await response.json();
                    for (const item of this.listReg) {
                        if (item.code === this.reg) {
                            this.curReg = item;
                            break;
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник регионов
        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            let refItem;
            switch (refName) {
                case 'bcPlanYear':
                    refItem = this.$refs.bcPlanYear;
                    break;
                case 'bcRegion':
                    refItem = this.$refs.bcRegion;
                    break;
                case 'bcVersion':
                    refItem = this.$refs.bcVersion;
                    break;
                case 'bcVariant':
                    refItem = this.$refs.bcVariant;
                    break;
                default:
                    break;
            }
            setTimeout(() => refItem.$el.focus(), 100);
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение
        // async changeFilters() {
        //     await this.loadVariants();
        //
        // },
        chgRegionOrYear() {
            this.cYear = this.planPeriod.year
            this.loadVariants();
        },
        chgData() {
            this.cYear = this.planPeriod.year
            if (this.curReg != null) {
                this.local_reg = this.curReg.code.toString()
                if (this.curVariant) {
                    const data = {
                        year: this.cYear,
                        dataType: this.dataType,
                        region: this.local_reg,
                        version: this.version,
                        variant: this.curVariant.variant_uuid,
                        view: this.view,
                        develop: this.develop
                    };
                    this.$emit('chgData', data);
                }
            }
        },
        // ------------- Варианты -------------
        async loadVariants() {
            this.local_reg = this.curReg.code.toString();
            this.variantBase = [];
            this.variantLst = [];
            this.curVariant = null;
            const param = {dataType: this.dataType, region: this.local_reg, curYear: parseInt(this.planPeriod.year)};
            try {
                const result = await fetch('/api-py/get-budg-variants-list/' + encodeURI(JSON.stringify(param)));
                if (result.status === 200) {
                    const json = await result.json();
                    console.debug("var_len", json.length);
                    const getVariantName = variantName;
                    for (const el of json) {
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        el.name_ru = getVariantName(el);
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        el.name_kk = getVariantName(el, 'name_kk');
                        // eslint-disable-next-line @typescript-eslint/camelcase
                        el.name_en = getVariantName(el, 'name_en');
                    }
                    this.variantBase = json;
                    if (this.variantBase.length > 0) {
                        for (const el of this.variantBase) {
                            this.variantLst.push(this.setNameLang(el));
                        }
                        this.curVariant = this.variantLst[0];
                    } else {
                        this.makeToast('warning', 'Баланс --> Версия бюджета', 'Нет доступных версий бюджета');
                    }
                } else {
                    this.makeToast('danger', 'get-budget-variants-lst', `${result.status} - ${result.statusText}`);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса get-budget-variants-lst', error.toString());
            }
        },
        setNameLang(obj, codeName) {
            let txt = obj['name_' + this.$i18n.locale];
            if (!txt) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        }
    }
};
</script>